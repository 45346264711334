export const getTypeGenCommand = (
  endpoint: string,
  token: string,
  excludeFiles: string[] = [],
  excludeThemeFiles: string[] = []
) => {
  return `npx apollo service:download
    --endpoint="${endpoint}" 
    --skipSSLValidation
    --header="Authorization: Bearer ${token}" 
    && npx apollo client:codegen 
      --target typescript 
      --localSchemaFile=schema.json 
      --tagName=gql 
      --includes='src/gql/*.ts'
      ${
        excludeFiles.length > 0
          ? "--excludes='src/gql/{" + excludeFiles.join(",") + "}.*'"
          : ""
      }
      --outputFlat src/gql/types
    && npx apollo client:codegen 
      --target typescript 
      --localSchemaFile=schema.json 
      --tagName=gql 
      --includes='src/LEOTheme/gql/*.ts' 
      ${
        excludeThemeFiles.length > 0
          ? "--excludes='src/LEOTheme/gql/{" +
            excludeThemeFiles.join(",") +
            "}.*'"
          : ""
      }
      --outputFlat src/LEOTheme/gql/types
      `;
};
